import React from "react";

import styled from "styled-components";

import Theme from "../utils/theme";

const lightBlue = Theme.Colors.alphaMod(Theme.Colors.blue, "0.75");

class GlobalFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Footer></Footer>
    );
  }
}

const Footer = styled.footer`
    width: 100%;
    background-color: ${lightBlue};
    height: 15vh;
`;


export default GlobalFooter;
